import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo-black.png';

import { getUserData } from '../Functions/Functions';
import { useTranslation } from 'react-i18next';
import '../languages/i18n';
import { ApiCall } from '../services/ApiCall';
import API from '../services/ApiLists';
import { CustomModal } from '../components/Loader';
import ButtonClick from '../components/ButtonClick';
import axios from 'axios';

function Splash2() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const { t } = useTranslation();
  const [privacycontent, setPrivacycontent] = useState('');
  const [tcModal, setTcModal] = useState(false);
  // const [locationPermission, setlocationPermission] = useState(true);
  const [error, setError] = React.useState(false);
  const [locationError, setLocationError] = useState(false);

  const [visited, setvisited] = useState(false);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  useEffect(() => {
    getPrivacyData();
    getDataVisited();
  }, []);

  function storeCoordinates(position) {
    setLocationError(false);
  }

  function errorHandler(e) {
    console.log('error', e);
    setLocationError(true);
  }

  useEffect(() => {
    (async function () {
      navigator.geolocation.getCurrentPosition(storeCoordinates, errorHandler, {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0,
      });

      window.ononline = (event) => {
        setError(false);
      };
      window.onoffline = (event) => {
        setError(true);
      };
    })();
  }, []);

  async function setDataVisited() {
    let data = await localStorage.setItem('visitedMobile', '1');
    console.log('visitedmovile', data);
  }
  async function getDataVisited() {
    let data = await localStorage.getItem('visitedMobile');
    console.log('visitedmovile', data);
    setvisited(data);
  }

  async function getPrivacyData() {
    const res = await axios.get('https://ipinfo.io/json/');
    fetch('https://apis.voteandfun.com/api/privacy-policy?region=' + res.data.county, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => {
        console.log('res getPrivacyData', res);
        if (res.success) {
          setPrivacycontent(res.data.content);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  }

  async function RouteFtn() {
    setTcModal(false);
    const userData = await getUserData();
    console.log('user data', userData);
    if (userData) {
      setUser(userData);
      if (window.isNative) {
        await ApiCall(
          'Post',
          API.UpdateDeviceToken,
          { device_token: window.isNative, user_id: userData?.id },
          {
            Authorization: 'Bearer ' + user?.access_token,
            Accept: 'application/json',
          },
        );
      } else {
        // const deviceToken = await requestForToken();
        // await ApiCall(
        //   'Post',
        //   API.UpdateDeviceToken,
        //   {
        //     device_token: deviceToken,
        //     user_id: user?.id,
        //   },
        //   {
        //     Authorization: 'Bearer ' + user?.access_token,
        //     Accept: 'application/json',
        //   },
        // );
      }
      if (userData?.login_as == 'business') {
        let rst = localStorage.getItem('cryptedData');
        navigate('/BusinessHome/' + rst);
      } else {
        navigate('/Home');
      }
    } else {
      navigate('/Home');
    }
  }
  return (
    <div
      class="splash-wrap container-fluid d-flex justify-content-center"
      onClick={() => {
        /android|iPad|iPhone|iPod/.test(userAgent) && visited == null ? setTcModal(true) : RouteFtn();
      }}
    >
      <img src={'gifs/VFnormal.gif'} alt="loading..." />

      <CustomModal showClose={false} topClassName="" open={locationError} setOpen={setLocationError}>
        <div
          style={{
            justifyContent: 'center', // Center horizontally
            alignSelf: 'center', // Center vertically
            // flexDirection: 'column',
            flex:1,
            width:'100%',
            alignItems :'center'
          }}
        >
          <div class="alert-bubble-img">
            <img class="img-fluid" src="../../images/alert-msg-bubble.png" alt="ico" />
            <div class="cont">
              <p style={{ fontSize: 15,marginTop :10 }}>{t('alerts.locationTitle')}</p>
            </div>
          </div>
          <button
            style={{
              backgroundColor: '#ffd306',
              borderWidth: 0,
              width: '90%',
              padding:5,
              margin: 20,
              paddingTop :10,
              borderRadius: 25,
              alignSelf:'center',
              marginLeft :'5%',
              justifyContent:'center'
            }}
            onClick={() => {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  opensetting: true,
                }),
              );
            }}
          >
            <h5 style={{ alignSelf: 'center', fontWeight: 'bold', marginTop: 5,fontSize :12 }}>{t('alerts.enablelocation')}</h5>
          </button>

          <h5
            onClick={() => {
              setLocationError(false);
            }}
            style={{ textAlign: 'center', fontWeight: 'bold', marginTop: 5 ,fontSize :14 }}
          >
            {t('alerts.continue')}
          </h5>
        </div>
      </CustomModal>

      <CustomModal backgroundColor={'#ffd306'} showClose={false} topClassName="" open={tcModal} setOpen={setTcModal}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            height: '100px', // Set height of the row (adjust as needed)
            backgroundColor: '#ffd306',
          }}
        >
          <img
            class="img-fluid"
            src={logo}
            style={{
              height: '65px', // Image height
              // width: '65px'
            }}
            alt="Vote and Fun"
          />
        </div>
        <div
          style={{
            backgroundColor: 'white', // White background
            height: '60vh', // 60% of the viewport height
            overflowY: 'auto', // Enable vertical scrolling
            padding: '16px', // Optional padding
            boxSizing: 'border-box', // Ensure padding is included in the height calculation
            marginBottom: 15,
            margin: '20px',
            backgroundColor: 'white',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: privacycontent }} />
        </div>
        Continue to accept
        <ButtonClick
          title={t('login_screen.continue')}
          onClickftn={() => {
            setDataVisited();
            RouteFtn();
          }}
        />
      </CustomModal>
    </div>
  );
}

export default Splash2;
